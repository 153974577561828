import Link from "next/link";

export default function CustomLink({
  children,
  ariaLabel,
  href = "",
  scroll = true,
  ...rest
}) {
  const renderedAriaLabel =
    ariaLabel ||
    (() => {
      if (children?.innerText) return children.innerText;
      else {
        const paths = href?.match(/[^\/]+$/);
        if (paths?.length) return paths[0];
      }
    })();

  return (
    <Link aria-label={renderedAriaLabel} href={href} scroll={scroll} {...rest}>
      {children}
    </Link>
  );
}
