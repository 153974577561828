"use client";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function Carousel({ children, className }) {
  const ref = useRef(null);
  const [leftBtnVisible, setLeftBtnVisible] = useState(false);
  const [rightBtnVisible, setRightBtnVisible] = useState(false);

  const handleDetectVisibleBtn = useCallback(() => {
    const wrapper = ref.current;

    if (!wrapper) return;

    const maxScrollLeft = wrapper.scrollWidth - wrapper.clientWidth;

    if (wrapper.scrollLeft === 0) setLeftBtnVisible(false);
    else if (!leftBtnVisible) setLeftBtnVisible(true);

    if (Math.ceil(wrapper.scrollLeft) >= maxScrollLeft) {
      setRightBtnVisible(false);
    } else if (!rightBtnVisible) {
      setRightBtnVisible(true);
    }
  }, [leftBtnVisible, rightBtnVisible]);

  useEffect(() => {
    const wrapper = ref.current;

    if (!wrapper) return;

    handleDetectVisibleBtn();

    let isMouseDown = false;
    let startX, scrollLeft;

    wrapper.addEventListener("mousedown", (e) => {
      isMouseDown = true;
      startX = e.pageX - wrapper.offsetLeft;
      scrollLeft = wrapper.scrollLeft;
    });

    wrapper.addEventListener("mouseleave", () => {
      isMouseDown = false;
    });

    wrapper.addEventListener("mouseup", () => {
      isMouseDown = false;
    });

    wrapper.addEventListener("mousemove", (e) => {
      if (!isMouseDown) return;

      const x = e.pageX - wrapper.offsetLeft;
      // 3 là tốc độ scroll
      const walk = (x - startX) * 3;
      wrapper.scrollLeft = scrollLeft - walk;

      handleDetectVisibleBtn();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollLeft = useCallback(() => {
    const wrapper = ref.current;

    if (!wrapper) return;
    wrapper.scrollLeft -= 200;

    handleDetectVisibleBtn();
  }, [handleDetectVisibleBtn]);

  const handleScrollRight = useCallback(() => {
    const wrapper = ref.current;

    if (!wrapper) return;
    wrapper.scrollLeft += 200;

    handleDetectVisibleBtn();
  }, [handleDetectVisibleBtn]);

  return (
    <div
      className={`relative flex flex-row items-center justify-center w-full max-w-full overflow-hidden ${className}`}
    >
      {leftBtnVisible && (
        <button
          className="absolute transition-all font-bold select-none bg-gradient-to-r from-[#ffffff] to-[rgb(181, 151, 101)/0] w-[40px] h-full border-none outline-none box-border left-0 top-0 z-10 flex flex-row justify-center cursor-pointer items-center px-2.5"
          onClick={handleScrollLeft}
        >
          <FaChevronLeft />
        </button>
      )}
      <div
        className="relative flex flex-row items-center justify-center w-full max-w-full overflow-hidden transition-all cursor-grab active:cursor-grabbing"
        ref={ref}
      >
        {children}
      </div>
      {rightBtnVisible && (
        <button
          className="absolute top-0 right-0 transition-all font-bold w-[40px] h-full bg-gradient-to-l from-[#ffffff] to-[rgb(181, 151, 101)/0] border-none outline-none flex flex-row justify-center items-center box-border z-10 cursor-pointer px-2.5"
          onClick={handleScrollRight}
        >
          <FaChevronRight />
        </button>
      )}
    </div>
  );
}
