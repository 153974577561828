import Image from 'next/image';
import Link from "~/_components/atoms/Link";
import { FaArrowRight } from "react-icons/fa";

const Category = ({ data = null, isLoading = true, visible = true }) => {
  return (<div className={`relative w-full overflow-hidden rounded cursor-pointer aspect-image group ${isLoading ? "skeleton-loading" : ""} ${visible ? "block" : "hidden"}`}>
    {!isLoading && (
      <Link className="relative block w-full h-full overflow-hidden" href={`/${data?.slug || "products"}`}>
        <Image src={data?.image_url || ""}
          fill
          sizes="(max-width: 1024px) 100px, 300px"
          quality={85}
          alt={`image-${data?.slug}`}
          className="relative inset-0 object-cover w-full h-full transition-all scale-100 cursor-pointer group-hover:scale-105"
        />
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <span className="p-2 text-sm font-bold text-center bg-white rounded-full bg-opacity-80 text-gold drop-shadow-xl group-hover:hidden">{data?.name}</span>
          <span className="hidden p-2 text-sm font-bold text-center transition-all bg-white rounded-full bg-opacity-80 text-gold drop-shadow-xl group-hover:flex">
            <FaArrowRight size={20} />
          </span>
        </div>
      </Link>
    )}
  </div>);
}

export default Category;