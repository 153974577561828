"use client";
import { useCallback, useMemo, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Category from "~/_components/atoms/Category";

export default function ListCategories({ categories = [], maxItem = 6, isLoading = true }) {
    const [currentPage, setCurrentPage] = useState(0);

    const pages = useMemo(() => {
        if (isLoading) return 0;
        return Math.ceil((categories?.length || 0) / maxItem);
    }, [categories?.length, maxItem, isLoading]);

    const visibleItems = useMemo(() => {
        if (!categories?.length) return [];
        let startPoint = currentPage * maxItem;
        let endPoint = startPoint + maxItem - 1;

        const padding = categories?.length - 1 - endPoint;

        if (padding < 0) {
            startPoint += padding;
            endPoint += padding;
        }

        const result = [];

        for (let i = startPoint; i <= endPoint; i++) {
            result.push(i);
        }

        return result;
    }, [categories?.length, currentPage, maxItem]);

    const handleChangePage = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    return (
        <div className="relative flex flex-col w-full px-2 pt-3 bg-white mobile:px-4 tablet:pt-5 desktop:px-32 desktop-xl:px-48 h-fit">
            <div className="flex flex-row items-center justify-between pt-2 pb-4 h-fit">
                <h2 className="text-xl subpixel-antialiased font-bold text-left capitalize tablet:text-xl desktop:text-2xl w-fit min-w-fit">
                    Sản phẩm của chúng tôi
                </h2>
            </div>
            <div className="relative grid w-full grid-cols-2 grid-rows-1 gap-2 mobile:gap-4 desktop:gap-5 tablet:grid-cols-6 h-fit">
                {!isLoading && Array.isArray(categories)
                    ? categories.map((item, index) => (
                          <Category key={item.id} data={item} isLoading={isLoading} visible={visibleItems.includes(index)} />
                      ))
                    : Array.from({ length: maxItem }).map((_, index) => <Category key={index} data={{}} isLoading={isLoading} />)}

                <div
                    className={`absolute right-[calc(100%+20px)] h-full hidden ${
                        currentPage > 0 ? "desktop:flex" : ""
                    } items-center cursor-pointer`}
                >
                    <button
                        className="p-2 text-white rounded-full bg-gold ripple"
                        onClick={() => {
                            handleChangePage(currentPage - 1);
                        }}
                        aria-label="backward-page"
                    >
                        <FaChevronLeft size={20} className="rounded-full" />
                    </button>
                </div>
                <div
                    className={`absolute left-[calc(100%+20px)] h-full hidden ${
                        currentPage < pages - 1 ? "desktop:flex" : ""
                    } items-center cursor-pointer`}
                >
                    <button
                        className="p-2 text-white rounded-full bg-gold ripple"
                        onClick={() => {
                            handleChangePage(currentPage + 1);
                        }}
                        aria-label="toward-page"
                    >
                        <FaChevronRight size={20} className="rounded-full" />
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-4 h-fit">
                <div
                    className={`${
                        currentPage > 0 ? "visible desktop:invisible" : "invisible"
                    } flex items-center h-full cursor-pointer w-fit`}
                >
                    <button
                        className="btn-primary w-11"
                        onClick={() => {
                            handleChangePage(currentPage - 1);
                        }}
                        aria-label="backward-page"
                    >
                        <FaChevronLeft size={16} className="rounded-full" />
                    </button>
                </div>
                <div className="relative flex items-center justify-center w-fit">
                    {Array.from({ length: pages }).map((_, index) => (
                        <button
                            key={`select-page-category-${index}`}
                            className={`rounded-full w-[55px] h-[8px] transition-all ${index !== 0 ? "ml-2" : ""} ${
                                index === currentPage ? "bg-gold" : "bg-gray-light cursor-pointer hover:bg-gold"
                            }`}
                            onClick={() => {
                                handleChangePage(index);
                            }}
                            aria-label="change-page"
                        ></button>
                    ))}
                </div>
                <div
                    className={`${
                        currentPage < pages - 1 ? "visible desktop:invisible" : "invisible"
                    } flex items-center h-full cursor-pointer w-fit`}
                >
                    <button
                        className="btn-primary w-11"
                        onClick={() => {
                            handleChangePage(currentPage + 1);
                        }}
                        aria-label="toward-page"
                    >
                        <FaChevronRight size={16} className="rounded-full" />
                    </button>
                </div>
            </div>
        </div>
    );
}
